const mp = new MercadoPago('APP_USR-b5ef6471-179e-479a-8aff-05de7e0a9494', {
    locale: 'es-AR'
});

async function handleMercadoPago() {
    console.log("Botón presionado");

    try {
        const nombreElemento = document.querySelector("[data-nombre]");
        const cantidadElemento = document.querySelector("[data-cantidad]");
        const montoElemento = document.querySelector(".total-span");
    
        if (!nombreElemento || !cantidadElemento || !montoElemento) {
            throw new Error("Faltan elementos necesarios en el DOM (nombre, cantidad o monto).");
        }
    
        const totalTexto = montoElemento.textContent.replace(/\s+/g, '');
        const totalNumerico = parseFloat(totalTexto.replace(',', '.'));
    
        if (isNaN(totalNumerico)) {
            throw new Error("El valor total extraído no es un número válido.");
        }
    
        // Asignar el valor al objeto userBuyer
        const userBuyer = {
            nombreElemento: nombreElemento.getAttribute("data-nombre"),
            cantidadElemento: parseInt(cantidadElemento.getAttribute("data-cantidad")),
            montoElemento: totalNumerico,
        };
    
        if (!userBuyer.nombreElemento || isNaN(userBuyer.cantidadElemento) || isNaN(userBuyer.montoElemento)) {
            throw new Error("Datos inválidos en userBuyer.");
        }
    
        console.log("Datos extraídos:", userBuyer);
    
        const response = await fetch("https://t-papeleria-back.quinto.site/api/mp", {
            method: 'POST',
            headers: {
                'Accept': 'Application/json',
                'Content-Type': 'Application/json',
            },
            body: JSON.stringify(userBuyer)
        });
    
        const preference = await response.text();
        console.log("preference: " + preference);
    
        createCheckoutButton(preference);
    
    } catch (error) {
        console.error("Error en handleMercadoPago:", error);
    }
}

function createCheckoutButton(preferenceId) {
    const bricksBuilder = mp.bricks();

    const renderComponent = async () => {
        if (window.checkoutButton) {
            window.checkoutButton.unmount();
        }

        await bricksBuilder.create("wallet", "wallet_container", {
            initialization: {
                preferenceId: preferenceId,
            },
            customization: {
                texts: {
                    valueProp: 'Pagar con Mercado Pago',
                },
            },
        });
    };

    renderComponent();
}
